.green-bg {
  background-color: rgba(15, 176, 15, 0.1) !important ;
}
.yellow-bg {
  background-color: rgb(250, 250, 210) !important ;
}
.yellow-clr {
  color: rgba(255,165,0,255)
}
.green-clr {
  color: rgba(15, 176, 15, 1);
}
.orange-bg {
  background-color: rgba(255, 130, 0, 0.1) !important ;
}
.orange-clr {
  color:rgba(255,128,1,255)
}
.blue-bg {
  background-color: rgba(0, 0, 160, 0.1) !important   ;
}
.blue-clr {
  color: rgba(0, 0, 160, 1);
}
.project-initiate-container {
  .ant-card {
    border-radius: 6px;
  }
  .red-bg {
    background-color: rgba(255, 60, 20, 0.1);
  }
  .red-clr {
    color: rgba(255, 60, 20, 1);
  }
  .ant-input-search {
    width: 450px;
    .anticon-search {
      &::before {
        border-left: none;
      }
    }
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #6b6b6b;
  }
  .new-project-card {
    background-color: rgba(128, 128, 207, 0.47);
    .title-create {
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 700;
      button {
        float: right;
        background-color: rgba(255, 130, 0);
        border: none;
      }
    }
    .project-input-row {
      display: flex;
      input {
        margin-right: 30px;
      }
    }
  }
  .project-status-card {
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    }
    &.active {
      box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    }
    span {
      padding-right: 5px;
    }
    .ant-card-body {
      display: flex;
      padding: 10px;
      & > div {
        &:first-child {
          div {
            height: 49px;
            width: 49px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            padding: 7px 0;
            // img {
            //     width: 100%;
            // }
          }
        }
        &:last-child {
          text-align: center;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 16px;
          font-weight: 700;
          padding-left: 10px;
          .active-project {
            font-size: 12px;
            color: #848484;
            border-top: 1px solid #d8d8d8;
            padding-top: 6px;
          }
        }
      }
    }
  }
  .switch-container {
    display: flex;
    margin-left: 1px;
    justify-content: center;
    align-items: center;
  }
  .project-init-header {
    .welcome-header-container {
      margin-bottom: 30px;
    }
  }
}
.ant-table {
  background-color: transparent;
  color: #000000;
  thead {
    tr {
      background-color: transparent !important;
      th {
        background-color: transparent !important;
        font-weight: 600;
        // text-align: center;
        .ant-table-filter-trigger-container {
          right: 135px;
        }
        &.ant-table-selection-column {
          visibility: hidden;
          pointer-events: none;
        }
        &:nth-child(5) {
          .ant-table-filter-trigger-container {
            right: 105px;
          }
        }
      }
    }
  }
  .ant-select-selector {
    border: none;
    border-bottom: 1px solid black;
    margin-top: 4px;
  }
  tbody {
    tr {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      cursor: pointer;
    }
  }
  tr {
    background-color: white;
    &.expanded-row {
      .page-circle {
        background-color: rgba(0, 0, 160, 0.1);
        svg {
          path {
            fill: #0000a0;
          }
        }
      }
    }
    &.ant-table-row {
      font-weight: 700;
      font-size: 14px;
    }
    .page-circle {
      height: 49px;
      width: 49px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      background-color: #ececec;
      border-radius: 50%;
      svg {
        // height: 30px;
        margin-left: auto;
        margin-right: auto;
        path {
          fill: #454545;
        }
      }
      img {
        height: 30px;
        width: 28px;
        margin-left: 20%;
      }
    }
    .ant-table-cell {
      &.td-dropdown {
        // display: flex;
        // flex-direction: column;
      }
      padding: 7px 12px;
      background-color: transparent !important;
      // .ant-checkbox-inner {
      //     background-color: white;
      //     &::after {
      //         border-color: #1890ff;
      //     }
      // }
    }
    &.ant-table-expanded-row {
      background-color: transparent;
      .ant-table-cell {
        position: relative;
        height: 198px;
        .project-detail-card {
          background-color: white;
          position: absolute;
          top: -10px;
          width: 100%;
          left: 0;
        }
      }
    }
    .ant-table-selection-column {
      border-spacing: 0;
    }
    .ant-table-row-expand-icon-cell {
      & > span {
        svg {
          height: 25px;
          width: 25px;
          fill: #0000a0;
        }
      }
    }
  }
  .project-detail-card {
    padding-bottom: 15px;
    border-top: 4px solid #d8d8d8;
    .ant-row {
      padding: 15px;
      .ant-col {
        display: flex;
        &:last-child {
          justify-content: flex-end;
        }
        &:nth-child(2),
        &:nth-child(3) {
          justify-content: center;
        }

        .kpi-list-container {
          & > div {
            margin-bottom: 10px;
            b {
              font-weight: 800;
              padding-left: 5px;
              .dim-info {
                padding-left: 5px;
              }
            }
          }
        }
      }
    }
    .details-action-row {
      padding-left: 30px;
      display: flex;
      button {
        b {
          padding-left: 7px;
        }
        margin-right: 25px;
      }
      .input-button {
        display: flex;
        align-items: center;
        &:last-child {
          margin-left: auto;
        }
        img {
          margin-right: 8px;
        }
        svg {
          margin-right: 5px;
          path {
            fill: #ffffff;
          }
        }
        margin-right: 25px;
      }
    }
  }
}
.ant-table table {
  border-spacing: 0 10px;
}
