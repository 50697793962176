.side-nav-menu {
  li {
    a {
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
        width: 20px;
      }
    }
  }
}
.project-input-page {
  display: flex;
  height: 100%;
  .project-left-navigation {
    min-width: 250px;
    .side-nav-dropdown-container {
      .side-nav-dropdown {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 12px;
        height: 40px;
        span {
          display: flex;
          align-items: center;
        }
        svg {
          margin-right: 10px;
          &:last-child {
            margin-left: auto;
          }
        }
      }
      border-bottom: 0.5px solid #dddddd;
      padding: 0;
      padding-bottom: 18px;
      margin-bottom: 18px;
    }
    &.collapsed {
      min-width: 70px;
      .side-nav-dropdown-container {
        button {
          svg {
            &:first-child {
              display: none;
            }
          }
          padding: 4px;
        }
      }
      & > div {
        span {
          &:last-child {
            display: none;
          }
        }
      }
      .arrow-back {
        .back-title {
          display: none !important;
        }
        span {
          display: flex !important;
        }
      }
    }
    font-size: 14px;
    background-color: #ffffff;
    & > div {
      cursor: pointer;
      border-radius: 4px;
      margin: 10px 6px;
      display: flex;
      align-items: center;
      padding: 9px;
      &.right-nav-link {
        svg {
          margin-right: 10px;
        }
      }
    }
    .arrow-back {
      img {
        margin-right: 15px;
      }
      padding-left: 20px;
      margin-bottom: 20px;
      margin-top: 20px;
      position: relative;
      .collapse-icon {
        height: 21px;
        width: 21px;
        z-index: 20;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 2px;
        background-color: #ffffff;
        position: absolute;
        right: -15px;
        span {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .active {
      color: #0000a0;
      background: #e3e3e3;
      font-weight: 700;
      svg {
        path {
          fill: #0000a0;
        }
      }
    }
  }
  .ant-input {
    text-align: right;
  }
  .project-right-content {
    flex-grow: 1;
    padding: 0 25px 0 25px;
    overflow: auto;
    .page-info {
      margin-top: 15px;
      font-size: 13px;
    }
    .right-container-top-bar {
      display: flex;
      flex-direction: row-reverse;
      // margin-bottom: 10px;
      background-color: #f0f3f5;
      margin: 0 -25px;
      padding: 25px 25px 10px 25px;
      position: sticky;
      top: 0;
      z-index: 11;
      span {
        margin-right: auto;
      }
      button {
        display: flex;
        align-items: center;
        background: #e3e3e3;
        border-radius: 4px;
        margin-right: 10px;
        svg {
          margin-right: 7px;
        }
        &:disabled {
          svg {
            path {
              fill: rgba(0, 0, 0, 0.25);
            }
          }
        }
      }
    }
    .ant-tabs-editable-card {
      margin-top: 32px;
    }
    .bottom-navigation-row {
      display: flex;
      margin: 15px 0;
      button {
        border-radius: 4px;
        background-color: #e3e3e3;
        font-weight: 600;
        color: #0000a0;
      }
      .next-button {
        margin-left: auto;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        .anticon {
          margin-left: 7px;
        }
      }
    }
  }
  .sesitivity-table-container {
    // table {
    //     margin-bottom: 30px;
    // }
    // .cutsom-input-container {
    //     width: 100px;
    // }
    display: flex;
    table {
      width: 49.5%;
      margin-bottom: 15px;
    }
    flex-wrap: wrap;
    justify-content: space-between;
    @media only screen and (max-width: 800px) {
      flex-direction: column;
      table {
        width: 100%;
      }
    }
  }
  table {
    font-size: 12px;
    width: 100%;
    &.pl_table {
      thead {
        tr {
          th {
            text-align: center;
          }
          &:nth-child(2) {
            th {
              top: 102px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
              background-color: #f2f2f2;
              text-align: center;
            }
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9) {
              text-align: center;
            }
          }
        }
      }
    }
    &.pl-table-ba {
      .v2{   max-width: 40px;
         min-width: 40px;
        border-right: solid 4px #CFCF80;
        border-spacing: 3px;
      }
      thead {
        tr {
          &:first-child {
            th {
              text-align: center;
              &:first-child {
                position: sticky;
                left: 0;
                z-index: 5;
                text-align: left;
              
              }
              
            }
            
          }
        }
      }
      tbody {
        tr {
          td {
            min-width: 100px;
            &:first-child {
               position: sticky;
            left: 0;
            z-index: 5;
              min-width: 150px;
              font-weight: 600;
            }
            &:last-child {
              font-weight: 400;
            }
          }
          &.total-row {
            td {
              font-weight: 600;
            }
          }
        }
      }
    }
    &.sesitivity-table {
      thead {
        tr {
          th {
            background-color: #e5e5e5;
            color: #000000;
            &:first-child {
              color: #0000a0;
              font-size: 14px;
            }
          }
          &:first-child {
            th {
              &:nth-child(2) {
                color: #0000a0;
                font-size: 14px;
              }
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:nth-child(1),
            &:nth-child(2) {
              background-color: #e5e5e5;
              font-weight: 600;
            }
            &:last-child {
              font-weight: 400;
            }
          }
        }
      }
    }
    &.growth-table {
      thead {
        th {
          &:last-child {
            width: 40px;
            padding: 0;
          }
        }
      }
      tbody {
        tr {
          td {
            &:last-child {
              padding-right: 15px;
              padding-left: 0px;
            }
          }
        }
      }
    }
    &.sticky-header-table {
      thead {
        th {
          position: sticky;
          top: 65px;
          z-index: 10;
        }
      }
    }
    &.project-overview-table {
      th,
      td {
        text-align: center !important;
      }
      td {
        width: 10%;
        &:last-child {
          font-weight: 400;
        }
      }
      &.estimate-table {
        td {
          &:nth-child(4),
          &:nth-child(5) {
            width: 20%;
          }
        }
      }
    }
    &.depriciation-table {
      thead {
        th {
          &:first-child {
            width: 100px;
            text-align: right;
          }
          &:nth-child(2) {
            width: 250px;
            text-align: center;
          }
        }
      }
      tbody {
        tr {
          td {
            &:first-child {
              text-align: right;
            }
            &:nth-child(2) {
              text-align: left;
            }
          }
        }
      }
      .total-row {
        border-top: 1px solid #c4c4c4;
      }
    }
    thead {
      tr {
        color: #ffffff;
        th {
          width: 150px;
          background-color: #8080cf;
          padding: 10px 5px;
          text-align: right;
          &:last-child {
            font-weight: 600;
            padding-right: 28px;
          }
          &:first-child {
            text-align: left;
            padding-left: 28px;
            width: 250px;
          }
          button {
            padding-top: 3px;
            svg {
              width: 20px;
              height: 20px;
              path {
                fill: white;
              }
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          text-align: right;
          background-color: #ffffff;
          &.td-danger {
            color: red;
          }
          &.delete-hover-icon {
            position: relative;
            svg {
              margin-left: 5px;
              cursor: pointer;
              path {
                fill: #c4c4c4;
              }
            }
            span {
              position: absolute;
              right: 0;
              top: 15px;
              display: none;
            }
            &:hover {
              span {
                display: flex;
              }
            }
          }
          .dim-info {
            color: #959595;
            font-size: 10px;
            padding-left: 5px;
            line-height: 13px;
          }
          // white-space: nowrap;
          padding: 9px 5px;
          .info-td-label {
            font-size: 10px;
            color: #959595;
            padding-left: 5px;
          }
          &:last-child {
            font-weight: 600;
            padding-right: 28px;
            text-align: right;
          }
          &:first-child {
            padding-left: 28px;
            text-align: left;
          }
        }
        &.top-border-seperator {
          border-top: 1px solid #c4c4c4;
        }
        &.timex-row {
          td {
            &:nth-child(4n + 1) {
              border-right: 0.5px solid #a4a4a4;
            }
            &:nth-child(1) {
              border-right: none;
            }
          }
        }
        &.add-tem-row {
          td {
            padding-top: 0px;
            text-decoration: underline;
            font-size: 12px;
            font-weight: 700;
            & > span {
              cursor: pointer;
            }
            text-align: left !important;
            .anticon {
              margin-left: 5px;
            }
          }
        }
        &.seperator-row {
          border-bottom: 1px solid #a9a9a9;
          color: #0000a0;
          .anticon {
            margin-left: 10px;
            svg {
              fill: #0000a0;
              border: 2px;
            }
          }
          &.expand-start {
            td {
              &:first-child {
                // padding-left: 10px;
                .anticon {
                  margin-right: 8px;
                }
                .anticon-info-circle {
                  margin-left: 8px;
                  svg {
                    fill: #000000;
                  }
                }
                display: flex;
                align-items: center;
              }
            }
          }
          td {
            background-color: #e9e9e9;
            &:first-child {
              font-weight: 600;
            }
            &:last-child {
              // color: #8F8F8F;
            }
            &.switch-container {
              span {
                display: flex;
                align-items: center;
                button {
                  margin-right: 10px;
                }
              }
            }
          }
        }
        &.total-row {
          font-weight: 600;
        }
        &.border-top-row {
          border-top: 1px solid #c4c4c4;
        }
        &.total-dark-row {
          font-weight: 600;
          color: #ffffff;
          td {
            background-color: #3c3c3c;
          }
        }
        &.stolen-row {
          color: #ff8200;
          td {
            &:last-child {
              color: #000000;
            }
          }
        }
      }
      .ant-input-affix-wrapper {
        padding: 3px 5px;
      }
    }
    &.timing-table {
      width: calc(90 * 26px);
      overflow: auto;
      thead {
        tr {
          &:last-child {
            th {
              top: 37px;
            }
          }
        }
      }
      tr {
        &:first-child {
          th {
            &:first-child {
              text-align: left;
            }
            &:last-child {
              text-align: right;
            }
            text-align: center;
          }
        }
        td {
          input {
            min-width: 75px;
          }
          &:first-child {
            position: sticky;
            left: 0;
            z-index: 5;
          }
        }
        th {
          &:first-child {
            left: 0;
            z-index: 25;
          }
          position: sticky;
          top: 0;
          z-index: 20;
          // &:last-child {
          //     position: sticky;
          //     right: 0;
          //     z-index: 5;
          // }
        }
      }
    }
  }
  .scroll-y-container {
    width: 100%;
    overflow: auto;
  }
  .ant-tabs-tab-remove {
    display: none;
  }
  .tabs-row {
    margin: 30px 0;
    span {
      margin-right: 45px;
      font-size: 14px;
      padding-bottom: 3px;
      cursor: pointer;
      &.active {
        border-bottom: 2px solid #000000;
        font-weight: 600;
      }
    }
  }

  .ant-tabs-nav {
    margin: 0;
    .three-dots {
      // margin-left: 20px;
      // margin-right: 15px;
    }
    .ant-tabs-tab {
      // border: 1px solid  !important;
      margin-right: 6px !important;
      font-weight: 600;
      padding: 6px 8px !important;
      background-color: #bababa;
      &.ant-tabs-tab-active {
        background-color: #8080cf;
        div {
          color: #ffffff;
        }
        svg {
          path {
            fill: #ffffff;
          }
        }
      }
    }
    button {
      background: transparent !important;
    }
  }
  // .ant-tabs-tabpane {
  //     border-top: 3px solid #8080CF;
  // }
  table{
    &.carbon-footprint-table {
      thead {
        tr {
          th{
            text-align:center;
            &:last-child{
              text-align:center;
            }
          }
          &:nth-child(2){
            th{
              &:last-child{
                width: 200px;
              }
            }
          }
        }
      }
      tbody {
        tr {
          td{
            text-align:center;
            &:last-child{
              text-align:center;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}
.super-user-input {
  padding: 20px;
}
.ant-table-filter-column {
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  .ant-table-column-title {
    flex: unset;
  }
}
.cards-row-2 {
  margin-bottom: 20px;
  .cards-list {
    display: flex;
    flex-wrap: wrap;
  }
  .kpi-number {
    font-size: 36px;
    line-height: 47px;
    align-items: center;
    font-weight: bold;
    color: #0000a0;
  }
  .cards-row-title-2 {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    margin-bottom: 5px;
  }
  .r-card-container {
    min-width: 20%;
    padding: 5px;
    .kpi-card-2 {
      height: 100%;
      .ant-card-body {
        height: 100%;
        justify-content: center;
        display: flex;
        flex-direction: column;
        position: relative;
        .card-actions {
          position: absolute;
          top: 5px;
          right: 5px;
          cursor: pointer;
        }
      }
    }
  }
  .ant-card {
    border-radius: 6px;
    text-align: center;
    // margin-right: 11px;
    .ant-card-body {
      border-radius: 6px;
      padding: 8px;
    }
  }
  .kpi-title {
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    font-weight: bold;
    color: #525252;
  }
}
.benefits-recommend-table{
  width: unset !important;
  margin-bottom: 15px;
  td, th {
    text-align: center !important;
  }
  tbody {
    td {
      font-weight: 600;
    }
  }
  thead {
    tr {
      &:last-child {
        td {
          width: 100px !important;
        }
      }
    }
  }
}
.pop-over-growth {
  & > div {

  }
  min-width: 350px;
  table {
    margin-bottom: 10px;
    width: 100%;
    thead {
      background-color: #8080cf;
      tr {
        &:nth-child(1), &:nth-child(2) {
          text-align: center;
        }
        th {
          // min-width: 100px;
          color: white;
        }
      }
    }
    tbody {
      tr {
        td{
          &:first-child {
            font-weight: 600;
          }
          padding: 2px 0;
        }
      }
    }
  }
}
