.next-button {
  background: #0000a0;
  border-radius: 4px;
  color: white;
  border: none;
}
.save-button {
  color: #0000a0;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  padding: 5px 0 !important;
  svg {
    margin-right: 7px;
  }
  margin-right: 30px;
}
.site-collapse-custom-collapse {
  .total-row {
    display: flex;
    justify-content: space-between;
    b {
      padding-left: 3px;
      display: inline-flex;
      justify-content: center;
      span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding-left: 3px;
      }
    }
  }
  .ant-collapse-item {
    .ant-collapse-header {
      border-bottom: 1px solid #a9a9a9;
      font-size: 12px;
      padding-left: 30px !important;
      display: flex;
      align-items: center;
      color: #0000a0 !important;
      font-weight: 600;
      cursor: default;
      svg {
        margin-right: 10px;
        height: 21px;
        path {
          fill: #0000a0;
        }
        &.check-circle {
          path {
            fill: #0fb00f !important;
          }
        }
      }
    }
    &:last-child {
      .ant-collapse-header {
        border-bottom: none;
      }
    }
    &.ant-collapse-item-active {
      .ant-collapse-header {
        background-color: #ffffff;
        border-bottom: 3px solid #d8d8d8;
      }
    }
  }
  form {
    font-size: 12px;
    input,
    textarea,
    .ant-select-selector,
    .ant-input-affix-wrapper {
      background-color: #f2f2f2 !important;
    }
    .project-id-label {
      .ant-form-item-explain {
        line-height: 15px;
        padding: 3px 0 5px 0;
      }
    }
    label {
      .anticon-info-circle {
        margin-left: 5px;
        svg {
          fill: #bdbdbd;
        }
      }
    }
    .ant-select-selector {
      border-bottom: 1px solid #c4c4c4 !important;
    }
    .ant-select-arrow {
      color: #000000;
    }
    .additional-label {
      margin-top: 30px;
      color: red;
      opacity: 0.8;
    }
    .benefits-input {
      .ant-form-item-explain {
        white-space: pre-line;
      }
    }
  }

  .site-collapse-custom-panel {
    background: #d8d8d8;
    // border-radius: 4px;
  }
  .ant-collapse-content-box {
    background-color: #ffffff;
    padding: 30px !important;
  }
}
.ant-select-item-option-content {
  .anticon-info-circle {
    display: none;
  }
}
.project-form-create-modal {
  .project-detail-edit-top-info {
    display: flex;
    margin-bottom: 10px;
    span {
      display: flex;
      align-items: center;
      margin-right: 20px;
      img {
        margin-right: 5px;
      }
    }
  }
  .ant-modal-body {
    background: #f0f3f5;
    border-radius: 4px;
  }
}
.project-add-button {
  margin-top: 15px;
  display: flex;
  flex-direction: row-reverse;
  button {
    border-radius: 5px;
    padding: 6px 25px;
  }
}
.dep-highlight-red {
  background-color: #ffebee !important;
}
.dep-highlight {
  background-color: #fffde7 !important;
}
