.simulation-container {
}
.simulation-table {
  font-size: 13px;
  thead {
    tr {
      background-color: #8080cf;
      color: white;
      th {
        padding: 5px;
      }
    }
  }
  tr {
    background-color: white;
    td {
      padding: 2px 14px !important;
      &:first-child {
      }
    }
    &.sub-title {
      font-weight: 600;
      text-decoration: underline;
    }
  }
}
.simulation-steps-text {
  p {
    font-size: 12px;
  }
}
.simulation-table-result {
  margin-bottom: 10px;
  tr {
    td {
      &:first-child {
        font-weight: 600;
      }
      &:last-child {
        font-weight: unset !important;
      }
    }
  }
}
.simulation-input-container {
  display: flex;
  & > div {
    width: 50%;
  }
  background-color: white;
  padding-bottom: 5px;
  margin-bottom: 20px;
}
.react-speedo-container {
  padding-top: 15px;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 20px;
  div {
    font-size: 18px;
    font-weight: 800;
    display: flex;
    justify-content: center;
  }
}
