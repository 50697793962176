.line-chart-container {
  position: relative;
  .sub-title {
    position: absolute;
    margin-left: 48%;
    top: 60px;
    font-weight: 600;
  }
  .line-chart-title {
    background: #8080cf;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: white;
    padding: 12px 12px 12px 30px;
    display: flex;
    .anticon-cloud-download {
      margin-left: auto;
      cursor: pointer;
      svg {
        height: 15px;
        width: auto;
      }
    }
    .anticon-cloud-download {
      display: none;
    }
  }
  &:hover {
    .anticon-cloud-download {
      display: block;
    }
  }
  background-color: white;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
  .legend-container {
    display: flex;
    .legend-group {
      padding: 10px;
      // outline: 1px solid #eeeeee;
      .group-title {
        margin-bottom: 10px;
        font-weight: 600;
        // background-color: #eeeeee;
        padding: 0 5px;
      }
      margin-right: 40px;
    }
    .line-legend {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
    .legend-label {
      font-size: 12px;
      line-height: 16px;
      color: #393939;
      padding-left: 10px;
    }
    margin-bottom: 20px;
  }
  margin-bottom: 35px;
}
