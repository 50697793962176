.login-layout {
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  // background-image: url('../../images/login-layout-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.signup-form {
  padding: 50px;
  .ant-form-item {
    margin: 0px;
  }
  .signup-button {
    margin-top: 20px;
  }
  label {
    color: rgba(85, 85, 85, 1);
    font-weight: 600;
  }
  .name-row {
    & > div {
      &:first-child {
        padding-right: 5px;
      }
      &:last-child {
        padding-left: 5px;
      }
    }
  }
}
.login-wrapper {
  max-width: 800px;
  // min-height: 500px;
  background-color: #fff;
  border-radius: 6px;
  min-width: 533px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: center;
  padding: 30px;
  &.simple {
    min-width: 320px;
    min-height: auto;
    padding: 40px 0 20px;
    .login-form-wrap {
      width: 100%;
      margin-top: 40px;
    }
    .login-bg {
      display: none;
    }
  }
}
.login-form-wrap {
  // width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  .gotoapp {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .welcome-text {
    text-align: center;
    font-size: 20px;
    img {
      height: 54px;
    }
  }
}
.login-bg {
  width: 40%;
  // background-image: url('./../../images/login-bg.jpg');
  background-position: center;
  background-size: cover;
  border-radius: 6px;
}
.login-form {
  margin-top: 40px;
  min-width: 320px;
  .ant-form-item {
    &:nth-child(3) {
      margin-bottom: 129px;
    }
    &:last-child {
      .ant-form-item-control-input-content {
        text-align: center;
      }
    }
  }
}
.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  align-self: center;
  border-radius: 5px;
  // width: 100%;
}
