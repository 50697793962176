.welcome-header-container {
  display: flex;
  & > div {
    &:last-child {
      // margin-left: auto;
    }
  }
  margin-bottom: 15px !important;
  margin-top: 20px;
  .welcome-msg {
    font-size: 22px;
    text-transform: capitalize;
  }
  .advanced-search {
    font-size: 14px;
    margin-left: 20px;
  }
}
