.thermometer-container {
  .bar-card {
    background-color: white;
    padding: 20px 80px;
    border-radius: 5px;
    position: relative;
    padding-top: 100px;
    .bar-title {
      font-size: 25px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 10px;
      position: absolute;
      top: 10px;
      left: 70px;
      width: 90px;
      display: flex;
      justify-content: center;
      height: 80px;
      align-items: center;
    }
    .bar-outline {
      padding: 10px;
      background-color: gray;
      border-radius: 15px;
      width: fit-content;
      .bar {
        width: 50px;
        height: 400px;
        position: relative;
        .sector {
          // height: 20%;
          // width: 100%;
          // &:nth-child(1),
          // &:nth-child(2),
          // &:nth-child(3) {
          //   height: 16.66%;
          // }
          &:first-child {
            border-radius: 10px 10px 0 0;
          }
          &.last-sector {
            border-radius: 0 0 10px 10px;
            height: 10%;
          }
        }
        .refer-line {
          position: absolute;
          height: 4px;
          width: 180%;
          left: -40%;
          background-color: black;
          z-index: 5;
          & > div {
            position: absolute;
            width: 100%;
            top: -20px;
            text-align: center;
            font-weight: 600;
            background-color: rgba(255,255,255, 0.6);
          }
        }
        .bar-pointer {
          & > div {
            position: relative;
            text-align: center;
            border-radius: 4px;
            border: 2px solid #bdbdbd;
            z-index: 100;
            color: white;
          }
          position: absolute;
          width: 50px;
          &.bar-left {
            left: -80px;
            & > div {
              .arrow {
                position: absolute;
                width: 0;
                height: 0;
                right: -30px;
                top: 5px;
                border-top: 5px solid transparent;
                border-left: 30px solid #bdbdbd;
                z-index: -1;
                border-bottom: 5px solid transparent;
              }
            }
          }
          &.bar-right {
            right: -80px;
            & > div {
              .arrow {
                position: absolute;
                width: 0;
                height: 0;
                left: -30px;
                top: 5px;
                z-index: -1;
                border-top: 5px solid transparent;
                border-right: 30px solid #bdbdbd;
                border-bottom: 5px solid transparent;
              }
            }
          }
        }
      }
    }
  }
}
