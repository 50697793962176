.local-currency {
  .ant-modal-body {
    height: 200px;
    overflow: auto;
    table {
      thead {
        width: 150px;
        background-color: #8080cf;
        color: white;
        th {
          padding: 2px 10px;
          width: 100px;
        }
      }
      tbody {
        td {
          padding: 2px 10px;
        }
      }
    }
  }
}
.super-user-input {
  min-height: 200px;
  .currency-upload-row {
    display: flex;
    .sample-row {
      display: flex;
      align-items: center;
      margin-left: 20px;
    }
  }
  table {
    width: unset;
  }
}
#cssTable td
{
    text-align: center; 
    vertical-align: middle;
    width: 140px;
}
#cssTable th
{
    text-align: center; 
    vertical-align: middle;
    width: 140px;
    position: sticky;
  top: 0;
}