.site-header {
  display: flex;
  align-items: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  z-index: 1;
  justify-content: space-between;
  height: 45px;
  line-height: 45px;
  & > div {
    width: 33.33%;
    &:nth-child(2) {
      justify-content: center;
    }
    &:nth-child(3) {
      justify-content: flex-end;
    }
  }
  .project-name {
    padding: 0 10px;
    font-size: 14px;
    font-weight: 700;
    color: #626262;
    display: flex;
    align-items: center;
    span {
      svg {
        height: 15px;
        margin-right: 5px;
        path {
          fill: #0000a0 !important;
        }
      }
      margin-right: 10px;
      display: flex;
      align-items: center;
      img {
        margin-right: 5px;
      }
    }
  }
  .nav-right-link {
    display: flex;
    flex-direction: column;
    font-size: 9.6px;
    line-height: 11px;
    padding: 0 8px;
    img {
      height: 25px;
    }
    div {
      text-align: center;
      &:last-child {
        padding-top: 3px;
      }
    }
    color: #444444;
    .ant-dropdown-trigger {
      cursor: pointer;
    }
  }
  .site-logo {
    img {
      height: 40px;
      width: auto;
    }
  }

  .header-col {
    display: flex;
    align-items: center;
  }

  .mars-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .fractal-logo {
    margin-left: 10px;
  }
  .ant-dropdown-link {
    margin: 0 10px;
  }
}
