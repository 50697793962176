.year-card-container {
  display: flex;
  margin-bottom: 30px;
  & > div {
    margin-right: 40px;
    .year-period-title {
      font-size: 12px;
      line-height: 16px;
      color: #000000;
      margin-left: 10px;
    }
    .ant-card {
      border-radius: 6px;
      margin: 5px 10px;
      .ant-card-body {
        padding: 17px;
        & > span {
          font-weight: bold;
          font-size: 36px;
          line-height: 47px;
          color: #525252;
          &:first-child {
            color: #0000a0;
            padding-right: 30px;
          }
        }
      }
    }
  }
}
