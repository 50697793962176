img {
  max-width: 100%;
}

.example {
 margin:5% 14%;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
body {
  scroll-behavior: smooth;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.no-events {
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed;
}
h1,
h2 {
  font-family: $marsFontExtrabold;
}
h3,
h4,
h5,
h6 {
  font-family: $marsFontBold;
}
.ant-modal-mask {
  background-color: rgba(240, 243, 245, 0.8);
}
.ant-table-column-sorter {
  display: none;
}
.ant-btn-primary {
  background-color: #0000a0;
  box-shadow: 0px 6px 12px rgba(93, 159, 226, 0.25);
  border-radius: 6px;
  &:hover {
    background-color: #0000a0;
  }
}
.ant-dropdown-menu-item {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}
.ant-select-selection-placeholder {
  z-index: 2;
}
.ant-switch-checked {
  background-color: #0000a0;
}
.ant-select-selector {
  border: none !important;
  border-bottom: 1px solid #626262 !important;
}
.ant-select-item-option-selected {
  background-color: #f2f2f2 !important;
}
// .ant-switch {
//   border: 2px solid #0000A0;
// }
.ant-dropdown-menu-item-selected {
  background-color: #f2f2f2 !important;
}
.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: #0000a0;
  }
}
.ant-table-filter-trigger {
  &.active {
    svg {
      fill: #0000a0;
    }
  }
}
.ant-input-number {
  width: unset;
}
.ant-input-number-input {
  text-align: right;
  padding: 0 4px;
}
.ant-input-number-handler-wrap {
  display: none;
}
.dim-info {
  color: #959595;
  font-size: 10px;
}
.cutsom-input-container {
  background-color: white;
  border-radius: 2px;
  border: 1px solid #b9b9b9;
  display: flex;
  .suffix {
    display: flex;
    align-items: center;
    font-size: 13px;
    padding: 0 3px 0 2px;
  }
}
.card-export-area {
  position: relative;
  padding-right: 5px;
  background-color: #f1f1f1;
  &:hover {
    .anticon-cloud-download {
      display: unset;
    }
  }
  .anticon-cloud-download {
    display: none;
    svg {
      height: 20px;
      width: auto;
    }
    position: absolute;
    z-index: 2;
    right: 5px;
    top: 3px;
    height: 12px;
  }
}
.anticon-more {
  width: 25px !important;
  svg {
    width: 25px !important;
    height: auto !important;
  }
}
.custom-tooltip {
  background-color: white;
  border: 1px solid #eeeeee;
  padding: 10px;
}
.info-bold {
  padding-top: 5px;
  svg {
    fill: red;
    height: 15px;
    width: 15px;
  }
}
.custom-table {
  .ant-table {
    table {
      border-spacing: 0;
    }
    .ant-table-thead {
      background: #8080cf;

      .ant-table-cell {
        color: #fff;
        padding: 6px;
        border: 1px solid #fff;
        text-align: center;
        white-space: pre-wrap;
        text-overflow: ellipsis;
        background-color: #8080cf !important;
      }
    }
    .ant-table-tbody {
      .ant-table-row {
        box-shadow: none;

        .ant-table-cell {
          font-weight: 400;
          text-align: unset;
          font-size: 12px;
          padding: 6px 4px !important;
          background-color: #fff !important;
        }
      }
      
    }
  }
}
.small-font-table {
  .ant-table-thead,
  .ant-table-tbody {
    .ant-table-cell {
      font-size: 11px;
    }
    
  }
}
.path-gray path {
  fill:#c4c4c4;
}
.auto-height.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: auto;
}
.auto-height.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 22px;
}
.auto-height.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  white-space: normal;
  word-break: break-all;
}